<template>
  <div class="home">
    <div v-for="link in links" :key="link.path">
      <router-link :to="link.path">/{{ link.path }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  computed: {
    links() {
      const links = [...window.links];
      return links;
    }
  },
  created() {
    this.$router.push({ path: '/status' });
  }
};
</script>
